.footer {
  padding: 20px;
  background-color: #fef9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-top: 2mm ridge rgba(220, 50, 50, 0.6);
}

.logos {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 600px;
}

@media (max-width: 479px) {
  .logos {
    display: none;
  }
}

.logo1,
.logo2 {
  width: 150px;
  height: 100%;
}

.disclaimer {
  font-size: 0.875em;
  color: #666;
  text-align: center;
  max-width: 800px;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.disclaimer:hover,
.disclaimer:focus {
  transform: scale(1.1);
}
