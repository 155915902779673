.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 3px;
  background-color: #fef9f9;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 20px;
  padding: 10px;
}

.link {
  color: #f8f9fa;
  transition: all 0.3s ease;
  background-color: #ff8f00;
  border-radius: 5px;
  padding: 5px 20px;
  text-align: start;
}

.link:hover {
  color: #f8f9fa;
  background-color: black;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.active {
  color: #f8f9fa;
  background-color: black;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.productsContainer,
.helpContainer {
  position: relative;
  cursor: pointer;
}

.logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.logo1,
.logo4 {
  width: 9rem;
  transition: all 0.3s ease;
}

.logo:hover,
.logo:focus {
  transform: scale(1.1);
}

.burgerMenuIcon {
  cursor: pointer;
  position: relative;
  margin-left: auto;
}

.menuDropdown {
  cursor: pointer;
  position: relative;
}

.naContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.dropdownContent {
  display: flex;
  z-index: 999;
  flex-direction: column;
  position: absolute;
  top: 130%;
  right: 0;
  gap: 20px;
  padding: 10px;
  min-width: 103px;
  background-color: #ff8f00;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.additionalDropdown {
  display: flex;
  z-index: 999;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 120%;
  left: 0;
  width: 230px;
  background-color: #ff6600;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.menuDropdown:hover .dropdownContent {
  display: flex;
}

.linkWidth {
  width: 100%;
}

@media (max-width: 767px) {
  .logo1 {
    width: 7rem;
    height: 50%;
  }
  .logoLinkInvisible {
    display: none;
  }

  .header {
    justify-content: space-between;
    padding: 3px 20px;
  }
}

@media (max-width: 1279px) {
  .menuDropdown:hover .dropdownContent,
  .menuDropdown:focus-within .dropdownContent {
    display: flex;
  }
}

.backdrop {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  pointer-events: none;
  visibility: hidden;
  z-index: 998;
  background: rgba(0, 0, 0, 0.1);
}

.activeBackdrop {
  visibility: visible;
}
