@import url(https://fonts.googleapis.com/css?family=Pacifico:regular);

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.7;
  position: relative;
  z-index: 1;
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../src/images/bg-6.jpg');
  background-repeat: repeat;
  background-size: 40%;
  background-position: top;
  opacity: 0.2;
  z-index: -1;
}

main {
  padding-top: 5px;
  padding-bottom: 5px;

  min-height: 85vh;
}

@media (min-width: 768px) {
  main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}
